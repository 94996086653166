<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 674 674" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M532.124 169.792H673.784V105.029H532.124C395.93 105.029 375.599 187.69 375.599 236.961C375.599 280.303 390.414 314.345 418.797 336.924C390.414 359.528 375.599 393.57 375.599 436.888V568.849H447.215V436.917C447.215 403.701 457.305 369.743 532.114 369.743H673.774V304.135H532.124C457.315 304.135 447.225 270.192 447.225 236.961C447.225 203.729 457.315 169.821 532.124 169.821"
      fill="#D60A52"
    />
    <path
      d="M193.168 299.869L116.833 169.792H154.198C229.011 169.792 239.102 203.735 239.102 236.932C239.102 272.476 225.221 291.802 193.168 299.829M310.722 236.932C310.722 187.646 290.397 105 154.198 105H0L116.107 304.091H12.5426V568.85H84.1583V368.893H153.785L269.166 568.85H349.539L227.295 358.25C281.305 339.849 310.707 297.492 310.707 236.972"
      fill="#D60A52"
    />
  </svg>
</template>
